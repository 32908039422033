<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/knowledgeList' }">知识库列表</el-breadcrumb-item>
          <el-breadcrumb-item>编辑内容</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="form-box">
        <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px">
          <div class="form-title">基本信息</div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="标题" prop="title">
                <el-input v-model="form.title" placeholder="请输入标题" maxlength="30" show-word-limit></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="类型" prop="knowledgeType">
                <el-select v-model="form.knowledgeType" placeholder="请选择类型" @change="handleType">
                  <el-option
                    v-for="item in leixin"
                    :key="item.name"
                    :label="item.label"
                    :value="item.name"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="分类" prop="moduleId">
                <el-select v-model="form.moduleId" placeholder="请选择分类">
                  <el-option
                    v-for="item in classify"
                    :key="item.id"
                    :label="item.module"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="来源" prop="source">
                <el-input v-model="form.source" placeholder="请输入来源" maxlength="30" show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="摘要" prop="contentDescribe">
                <el-input v-model="form.contentDescribe" placeholder="请输入摘要" maxlength="100" show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          
          <!-- 基础信息中类型为文章时，显示添加文章模块 -->
          <div v-show="form.typeId==1">
            <div class="form-title">添加文章</div>
            <el-row :gutter="20">
              <el-col :span="24">
                <quill-editor ref="text" v-model="form.content" class="myQuillEditor" :options="form.editorOption"/>
              </el-col>
            </el-row>
          </div>
          <!-- 基础信息中类型为视频时，显示添加视频模块 -->
          <div v-show="form.knowledgeType=='video'">
            <div class="form-title">添加视频</div>
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item label="视频地址" prop="mediaFileUrl">
                  <el-input v-model="form.mediaFileUrl" placeholder="请输入视频地址"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <!-- 基础信息中类型为文档时，显示添加文档模块 -->
          <div v-show="form.knowledgeType=='document'">
            <div class="form-title">添加封面图</div>
            <el-upload
              :class="{hide:hideCoverUpload}"
              action="#"
              :limit="1"
              list-type="picture-card"
              :file-list="allFileList"
              :on-change="uploadChange"
              accept=".gif,.jpg,.jpeg,.png,.GIF,.JPG,.PNG"
              :auto-upload="false">
              <i slot="default" class="el-icon-plus"></i>
              <div slot="file" slot-scope="{ file }">
                <img class="el-upload-list__item-thumbnail" :src="file.url" />
                <span class="el-upload-list__item-actions">
                  <span
                    v-if="!disabled"
                    class="el-upload-list__item-delete"
                    @click="handleRemove(file)">
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </div>
            </el-upload>
            <div class="prompt">注意：图片尺寸370*230px，大小≤2MB，支持JPG、PNG、JPEG，最多上传1张</div>
            <div class="form-title">添加文档</div>
            <el-upload
              :class="{hide:hideDocUpload}"
              action="#"
              :limit="1"
              list-type="picture-card"
              :file-list="allPdfFileList"
              :on-change="uploadFileChange"
              accept=".pdf"
              :auto-upload="false">
              <i slot="default" class="el-icon-plus"></i>
              <div slot="file" slot-scope="{ file }">
                <div class="doc-content">
                  <img src="../../img/doc-pdf.png" />
                  <span class="doc-name" v-if="file.name">{{file.name}}</span>
                  <span class="doc-name" v-else>{{docSubStr}}</span>
                </div>
                <span class="el-upload-list__item-actions">
                  <span
                    v-if="!disabled"
                    class="el-upload-list__item-delete"
                    @click="handlePdfFileRemove(file)">
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </div>
            </el-upload>
            <div class="prompt">注意：文件大小≤5MB，支持PDF格式</div>
          </div>

          <div class="bottom-btn">
            <el-button type="primary" size="small" @click="$router.push('/knowledgeList')">取消</el-button>
            <el-button type="primary" @click="onSubmit" size="small">提交</el-button>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'
import { addQuillTitle } from '../../js/quill-title.js'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
export default {
  components: {
    quillEditor
  },
  data() {
    return {
      hideCoverUpload: false,
      hideDocUpload: false,
      limitCount: 1,

      loading: false,
      form: {
        content:'',
        mediaFileUrl:''
      },
      row: {},
      rules: {
        title:[{ required: true, message: '必填项', trigger: 'blur' }],
        knowledgeType:[{ required: true, message: '必填项', trigger: 'change' }],
        moduleId:[{ required: true, message: '必填项', trigger: 'change' }],
        contentDescribe:[{ required: true, message: '必填项', trigger: 'blur' }],
        content:[{ required: true, message: '必填项', trigger: 'blur' }],
        mediaFileUrl:[{ required: true, message: '必填项', trigger: 'blur' }],
      },
      disabled: false,
      dialogVideoUrl: '',
      dialogVisible: false,
      allFileList: [],
      imageIds: '',
      docIds: '',
      allVideoList: [],
      allPdfFileList: [],
      leixin: [
        // {name: '', label: '文章'},
        {name: 'video', label: '视频'},
        {name: 'document', label: '文档'}
      ],
      classify: [],
      docSubStr: ''
    };
  },
  methods: {
    initData() {
      this.form = {};
      this.allFileList = [];
      this.imageIds = '';
      this.docIds = '';
      this.allPdfFileList = []
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let formData = new FormData();
          console.log(this.form)
          if(this.form.title!=this.form.titleC){
            formData.append("title", this.form.title);
          }
          formData.append("knowledgeType", this.form.knowledgeType);
          if(this.form.moduleId!=this.form.moduleIdC){
            formData.append("moduleId", this.form.moduleId);
          }
          if(this.form.source!=this.form.sourceC){
            formData.append("source", this.form.source?this.form.source:null);
          }
          if(this.form.contentDescribe!=this.form.contentDescribeC){
            formData.append("contentDescribe", this.form.contentDescribe);
          }
          formData.append("id", this.form.id);

          if(this.form.knowledgeType=='video') {
            if(this.form.mediaFileUrl!=this.form.mediaFileUrlC){
                formData.append("mediaFileUrl", this.form.mediaFileUrl);
            }
          }else {
            if(this.allFileList.length==0) {
              this.$message.error("请选择封面图");
              return
            }
            if(this.allPdfFileList.length==0) {
              this.$message.error("请选择需要上传的文档");
              return
            } 
            this.allFileList.forEach((item) => {
              if(item.raw){
                formData.append("coverFile", item.raw);
              }  
            });
            this.allPdfFileList.forEach((item) => {
              if(item.raw){
                formData.append("documentFile", item.raw);
              }
            });
            if(this.imageIds) {
                formData.append("deleteCoverFileId", this.imageIds);
            }
            if(this.docIds) {
                formData.append("deleteDocumentFileId", this.docIds);
            }
          }

          this.loading = true;
          this.$ajax.post("knowledgeEdit", formData).then((res) => {
            this.loading = false;
            this.$message.success("成功");
            this.$router.push("/knowledgeList");
          }).catch(err=>{
            this.loading = false
          })
        }
      })
    },
    handleType(e) {
      if(e=='video') {
        this.form.mediaFileUrl = ''
      }else {
        this.form.mediaFileUrl = '1'
      }
      this.form.content = ''
    },
    // 封面图
    uploadChange(file, fileList) {
      this.allFileList = fileList;
      this.hideCoverUpload = fileList.length >= this.limitCount
    },
    handleRemove(file, fileList) {
      function checkAdult(item) {
        return item.uid != file.uid;
      }
      this.allFileList = this.allFileList.filter(checkAdult);
      this.imageIds = file.uid
      if(fileList) {
        this.hideCoverUpload = fileList.length >= this.limitCount
      }else {
        this.hideCoverUpload = false
      }
    },
    // 视频
    uploadVideoChange(file, fileList) {
      this.allVideoList = fileList;
      console.log(fileList)
    },
    handleVideoRemove(file) {
      function checkAdult(item) {
        return item.uid != file.uid;
      }
      this.allVideoList = this.allVideoList.filter(checkAdult);
    },
    handlePictureCardPreview(file) {
      this.dialogVideoUrl = file.url;
      this.dialogVisible = true;
    },
    // PDF文件
    uploadFileChange(file, fileList) {
      this.allPdfFileList = fileList;
      this.hideDocUpload = fileList.length >= this.limitCount
    },
    handlePdfFileRemove(file, fileList) {
      function checkAdult(item) {
        return item.uid != file.uid;
      }
      this.allPdfFileList = this.allPdfFileList.filter(checkAdult);
      this.docIds = file.uid
      if(fileList) {
        this.hideDocUpload = fileList.length >= this.limitCount
      }else {
        this.hideDocUpload = false
      }
    },
    loadData() {
      this.loading = true
      this.$ajax.post("knowledgeDetail", {}, {}, this.row.id).then((res) => {
        sessionStorage.setItem("detail", true);
        res.data.titleC = res.data.title
        res.data.knowledgeTypeC = res.data.knowledgeType
        res.data.moduleIdC = res.data.moduleId
        res.data.sourceC = res.data.source
        res.data.contentDescribeC = res.data.contentDescribe
        res.data.mediaFileUrlC = res.data.mediaFileUrl
        this.form = res.data;
        if(this.form.knowledgeType=='document') {
          this.allFileList.push({
              id: this.form.coverFileId,
              uid: this.form.coverFileId,
              url: this.form.coverFileUrl,
          });
          let docIndexOf = this.form.documentFileUrl.indexOf('fileId=')
          this.docSubStr = this.form.documentFileUrl.substr(docIndexOf+7)
          this.allPdfFileList.push({
              id: this.form.documentFileId,
              uid: this.form.documentFileId,
              url: this.form.documentFileUrl,
          })
          this.form.mediaFileUrl = '1'
          this.hideCoverUpload = true
          this.hideDocUpload = true
        }
        this.loading = false
      }).catch(err=>{
        this.loading = false
      })
    },
    // 获取栏目分类
    loadList() {
      this.loading = true
      this.$ajax.get("columnClassify").then((res) => {
        this.classify = res.data;
        this.loading = false
      }).catch(err=>{
        this.loading = false
      })
    },
  },
  mounted() {
    if (this.$route.params.row == null) {
      this.$router.push('/knowledgeList')
      return
    }
    this.row = this.$route.params.row
    addQuillTitle()
    this.loadData()
    this.loadList()
  }
};
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.add-checkContent {
  font-size: 14px;
  color: #66b1ff;
  line-height: 50px;
  padding-left: 28px;
  span {
    cursor: pointer;
  }
}
.el-icon-close {
  color: #1494fb;
  margin-left: 20px;
  cursor: pointer;
}
.prompt {
  font-size: 14px;
  color: #9e9e9e;
  margin: 20px 0;
}
.el-select {
  width: 100%;
}
.myQuillEditor {
  margin: 0 0 20px;
}
/deep/.el-upload-list--picture-card .el-upload-list__item {
  width: 240px;
  height: 135px;
}
/deep/.el-upload--picture-card {
  width: 240px;
  height: 135px;
}
.doc-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  .doc-name {
    width: 130px;
    text-align: center;
    word-wrap: break-word;
    font-size: 12px;
    color: #333;
    margin-top: 10px;
  }
}
</style>

<style>
.hide .el-upload--picture-card {
  display: none;
}
.editor {
  line-height: normal !important;
  height: 800px;
}
.ql-snow .ql-tooltip[data-mode="link"]::before {
  content: "请输入链接地址:";
}
.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  border-right: 0px;
  content: "保存";
  padding-right: 0px;
}

.ql-snow .ql-tooltip[data-mode="video"]::before {
  content: "请输入视频地址:";
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: "14px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
  content: "10px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
  content: "18px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
  content: "32px";
}

.ql-snow .ql-picker.ql-header .ql-picker-label::before,
.ql-snow .ql-picker.ql-header .ql-picker-item::before {
  content: "文本";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  content: "标题1";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  content: "标题2";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  content: "标题3";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  content: "标题4";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
  content: "标题5";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
  content: "标题6";
}

.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: "标准字体";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
  content: "衬线字体";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
  content: "等宽字体";
}
</style>